<template>
  <div class="ClassTime2">
    <div>
      <go-back-icon/>
      <!--      <timetable-title>{{ $route.query.className }}</timetable-title>-->
      <!--      <teacher-information/>-->
      <logout-icon/>
    </div>

    <div class="pans">
      <div v-for="i in dataRef.list"
           :key="i.uid"
           class="pan-item"
           :style="{
             background: `url(${i.bg}) no-repeat`,
             backgroundSize: 'cover',
             transform: `translateX(${-screenWidth * watchIndex}px)`
           }"
      >
        <div class="pan-content">
          <div v-for="ii in i.npcs" :key="ii.uid" class="pan-content--component"
               :style="{
                top: ii.posY * 2 + 'px',
                left: ii.posX * 2 + 'px'
               }"
          >
            <course-text-coll :text-data="ii" @click="onTextClick"/>
          </div>
        </div>
      </div>

<!--      <div-->
<!--          v-for="i in dataRef.list"-->
<!--          :key="i.uid"-->
<!--          class="pan-item"-->
<!--          :style="{-->
<!--            background: `url(${i.bg}) no-repeat`,-->
<!--            backgroundSize: 'cover',-->
<!--            transform: `translateX(${-screenWidth * watchIndex}px)`,-->
<!--          }"-->
<!--      >-->
<!--        <div class="pan-content">-->
<!--          <div v-for="ii in i.npcs" :key="ii.uid" class="pan-content&#45;&#45;component"-->
<!--               :style="{-->
<!--              top: ii.posY * 2 + 'px',-->
<!--              left: ii.posX * 2 + 'px',-->
<!--            }"-->
<!--          >-->
<!--            <div class="pan-content&#45;&#45;component__text"-->
<!--                 v-if="ii.type === 'text'"-->
<!--                 @click="onTextClick(ii)"-->
<!--            >-->
<!--              <div :class="`classtype-${ii.courseDto.inClass}`" class="clsstype" v-if="ii.courseDto"></div>-->
<!--              <div-->
<!--                  :style="{-->
<!--                      fontSize: ii.fsize + 'px',-->
<!--                      color: ii.fcolor,-->
<!--                      minWidth: '100px',-->
<!--                      backgroundColor: ii.bcolor,-->
<!--                      borderRadius: ii.rds ? '5px': '0',-->
<!--                      width: ii.w * 2 + 'px',-->
<!--                      height: ii.h * 2 + 'px',-->
<!--                 }"-->
<!--              >-->
<!--                {{ ii.n }}-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->


    </div>

    <div class="nav">
      <div class="nav-item">

        <div class="nav-item__icon" :class="{'active': watchIndex !== 0}" @click="onPanchange(-1)"></div>


      </div>
      <div class="nav-item">
        <div class="nav-item__icon" :class="{'active': watchIndex !== dataRef.list.length - 1}"
             @click="onPanchange(1)"></div>
      </div>
    </div>

    <a-modal
        v-model:visible="visible"
        centered
        :width="dataRef.chooseClasstime && dataRef.chooseClasstime.courseDto ? 1200: 694"
        :maskClosable="false"
        :footer="null"
        wrapClassName="modal-background-opacity"
        :closable="false"
    >
      <template #default>
        <div style="position: relative">
          <div style="position: absolute; cursor: pointer; right: -60px" @click="visible = false">
            <img src="@/assets/image/timetable/tip_close.png" alt="" draggable="false">
          </div>


          <div v-if="dataRef.chooseClasstime && dataRef.chooseClasstime.courseDto">
            <div class="topic_choose_alert">
              <div class="topic_choose_alert--title">
                第{{ dataRef.chooseClasstime.courseDto.courseIndex }}课
              </div>
              <div class="topic_choose_alert--classtimes">
                上课时间：{{
                  moment(dataRef.chooseClasstime.courseDto.bigDate).format('M月DD日')
                }}（{{ dataRef.chooseClasstime.courseDto.startTime }}-{{ dataRef.chooseClasstime.courseDto.endTime }}）
              </div>

              <div class="topic_choose_alert__topic">
                <div v-for="i in dataRef.modalList" :key="i.topicId" class="topic-select" @click="onCardClick(i)">
                  <div style="position: relative; margin: 10px;">
                    <img :src="decryptByDES(i.topicIcon)" :alt="i.topicName"
                         style="width: 320px; height: 240px; border-radius: 30px; object-fit: cover">
                    <div class="topic-select__title">
                      {{ i.topicName }}
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
          <div v-else>
            <img src="@/assets/image/timetable/no_topic_tip.png" alt="" draggable="false">
          </div>

        </div>
      </template>

    </a-modal>

  </div>
</template>

<script setup>
import GoBackIcon from "@/components/system/GoBackIcon";
import LogoutIcon from "@/components/system/LogoutIcon";
import {onMounted, onUnmounted, reactive, ref} from "vue";
import service from "@/utils/axios";
import {useRoute, useRouter} from "vue-router";
import {message, Modal as AModal} from "ant-design-vue";
import numberDigit from "@/utils/customComponentAPI/chineseNum2Num";
import moment from "moment";
import {decryptByDES} from '@/utils/DES'
import CourseTextColl from "@/components/timetable/CourseTextColl";

const route = useRoute()
const router = useRouter()

let visible = ref(false)
let screenWidth = ref(window.innerWidth)

const onResetScreen = () => {
  screenWidth.value = window.innerWidth
}

let dataRef = reactive({
  list: [],
  modalList: [],
  chooseClasstime: null
})

let watchIndex = ref(0)

const getTopicList = async (courseId) => {
  const r = await service.get('/dwart/an_teaching/classCourse/queryCourseTopicByCourseId', {
    params: {
      // courseId:,
      queryType: 1,
      courseId: courseId,
    }
  })
  if (r.length > 1) {
    dataRef.modalList = r;
    visible.value = true
  } else if (r.length === 0) {
    visible.value = true
  } else {
    await onCardClick(r[0])
  }

}


const onCardClick = async (item) => {
  if (item.forbid) {
    message.error('此课件已经下架');
    // console.log('sad')
    return false;
  }
  let _type = dataRef.chooseClasstime.courseDto.inClass;

  let routerName;

  switch (_type) {
    case 1:
      routerName = 'Courseware'
      break;
    case 2:
      routerName = 'CheckOrTeach'
      break;
    case 3:
      routerName = 'Courseware'
      break;
    default:
      return ''
  }


  await router.push({
    name: routerName,
    query: {
      topicId: item.topicId,
      topicName: item.topicName,
      scheduleId: dataRef.chooseClasstime.courseDto.scheduleId,
      courseIndex: dataRef.chooseClasstime.courseDto.courseIndex,
      classTime: numberDigit(dataRef.chooseClasstime.courseDto.courseIndex),
      beginDate: dataRef.chooseClasstime.courseDto.bigDate,
      startTime: dataRef.chooseClasstime.courseDto.startTime,
      endTime: dataRef.chooseClasstime.courseDto.endTime,
      classType: _type.toString(),
      ...route.query,
    }
  })
}


const getData = async () => {
  const r = await service.get('/dwart/an_teaching/classCourse/queryGameScen', {
    params: {
      classId: route.query.classId,
      queryType: 1
    }
  })

  let d = r.map(v => {
    return {
      id: v.id,
      uid: v.cId,
      cWidth: v.cWidth,
      cHeight: v.cHeight,
      bg: v.sceneUrl,
      npcs: v.npcs,
    }
  })
  d.forEach((v, k) => {
    if (v.npcs.filter(v=> v.courseDto.inClass === 2).length > 0){
      watchIndex.value = k
    }
  })
  dataRef.list = d;
}

const onTextClick = (item) => {
  dataRef.chooseClasstime = item;
  if (!item.courseDto){
    visible.value = true
    return
  }
  getTopicList(item.courseDto.scheduleId)
}

const onPanchange = (num) => {
  if (num === -1 && watchIndex.value === 0) {
    return
  }
  if (num === 1 && watchIndex.value === dataRef.list.length - 1) {
    return;
  }
  watchIndex.value += num
}

onMounted(() => {
  getData()
  window.onresize = () => {
    onResetScreen()
  }
})

onUnmounted(() => {
  window.onresize = null
})
</script>

<style lang="less" scoped>
@font-face {
  font-family: fz;
  src: url('https://img.dwhs.cn/cdn/font/chuyuan176.ttf');
}

.ClassTime2 {
  max-width: 100%;
  height: 100%;
  position: relative;
  user-select: none;

  .pans {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    z-index: 10;
    top: 0;
    left: 0;
    display: flex;

    .pan-item {
      height: 100%;
      transition: all .2s ease-in;

      .pan-content {
        width: 100vw;
        height: 100%;

        .pan-content--component {
          position: absolute;
          z-index: 999;
          display: flex;
          align-items: center;
          justify-content: center;

          .pan-content--component__text {
            cursor: pointer;
            transition: all .1s;
            position: relative;
            .clsstype{
              position: absolute;
              right: 0;
              top: -100%;
              width: 118px;
              height: 94px;
              &.classtype-1{
                background: url("~@/assets/image/timetable/t1.png") no-repeat;
                background-size: 100% 100%;
              }
              &.classtype-2{
                background: url("~@/assets/image/timetable/t2.png") no-repeat;
                background-size: 100% 100%;
              }
              &.classtype-3{
                background: url("~@/assets/image/timetable/t3.png") no-repeat;
                background-size: 100% 100%;
              }
            }
            &:hover {
              transform: scale(1.2);
            }

            div {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              min-height: 30px;
            }
          }
        }
      }
    }
  }

  .nav {
    position: absolute;
    z-index: 100;

    .nav-item {
      position: absolute;
      top: 50vh;
      transform: translateY(-50%);

      .nav-item__icon {
        width: 94px;
        height: 197px;
        transition: all .2s;
      }
    }

    .nav-item:nth-child(1) {
      //left: 50px;

      .nav-item__icon {
        background: url("~@/assets/image/timetable/icon_left.png") no-repeat;
        background-size: 100% 100%;
        cursor: not-allowed;

        &.active {
          background: url("~@/assets/image/timetable/icon_left_active.png") no-repeat;
          background-size: 100% 100%;
          cursor: pointer;

          &:hover {
            opacity: .5;
          }
        }
      }
    }

    .nav-item:nth-child(2) {
      right: -100vw;

      .nav-item__icon {
        background: url("~@/assets/image/timetable/icon_right.png") no-repeat;
        background-size: 100% 100%;
        cursor: not-allowed;

        &.active {
          background: url("~@/assets/image/timetable/icon_right_active.png") no-repeat;
          background-size: 100% 100%;
          cursor: pointer;

          &:hover {
            opacity: .5;
          }
        }
      }
    }
  }

}
</style>
